<template>
  <Layout>
    <b-row
      class="
        d-flex
        flex-row
        align-items-start
        justify-content-center
        w-100
        body-container
      "
      no-gutters
    >
      <!-- <b-col cols="12" lg="6" class="d-flex flex-column align-items-center justify-content-center logo-container">
        <b-img src="@/assets/personal-pac-logo-primary.svg" class="logo-img" alt="Personal PAC" fluid-grow></b-img>
      </b-col> -->
      <!-- <b-img src="@/assets/personal-pac-logo-primary.svg" class="bg-img" alt="Personal PAC" fluid-grow></b-img> -->
      <b-col
        cols="12"
        lg="8"
        xl="6"
        class="
          d-flex
          flex-column
          align-items-center
          justify-content-start justify-content-lg-center
          px-0
          py-3
          inner-container
        "
      >
        <slot></slot>
      </b-col>
    </b-row>
  </Layout>
</template>

<script>
import Layout from "@/layout/Layout";
export default {
  components: {
    Layout,
  },
};
</script>

<style scoped>
.body-container {
  min-height: 600px;
  height: 100vh;
  background-image: url("https://images.unsplash.com/photo-1561084711-b2e525f5a4c0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80");
  background-repeat: no-repeat;
  background-size: cover;
}
.body-container .bg-img {
  /* height: 200vh; */
  position: absolute;
  min-height: 600px;
}
.body-container .logo-container {
  height: 100vh;
  background-color: var(--primary);
}
.body-container .inner-container {
  height: 100vh;
  background-color: transparent;
}
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .body-container .logo-container {
    height: 25vh;
    overflow: hidden;
  }
  .body-container .inner-container {
    height: 75vh;
  }
}
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .body-container .logo-container {
    height: 20vh;
  }
  .body-container .inner-container {
    height: 80vh;
  }
}
</style>
