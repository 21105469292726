import axios from "axios";
import Config from "./config.js";

var baseURL = Config.baseURL;
// baseURL = " http://localhost:5000/api/dev";

let instanceToken = axios.create({
  baseURL: baseURL,
});

let instanceFileUpload = axios.create({
  baseURL: baseURL,
  headers: { "Content-Type": "multipart/form-data" },
});

// function to logout user
const logoutUser = () => {
  localStorage.clear();
  this.$router.replace({
    name: "Login",
  });
}

// Alter defaults after instance has been created
instanceToken.interceptors.request.use(
  function(config) {
    config.headers = {
      [Config.token]: JSON.parse(localStorage.getItem("adminDetails")).token,
    };
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor for normal requests
instanceToken.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status == 401) {
      // logout the user
      logoutUser();
    }
    return Promise.reject(error);
  }
);

// Alter defaults after instance has been created
instanceFileUpload.interceptors.request.use(
  function(config) {
    config.headers = {
      [Config.token]: JSON.parse(localStorage.getItem("adminDetails")).token,
    };
    console.log("font-awesome-icon" , config.headers)
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

const instance = axios.create({
  baseURL: baseURL,
});

export default {
  instanceToken: instanceToken,
  instance: instance,
  instanceFileUpload: instanceFileUpload,
};
